/**
 * Call payout invoice archive endpoint
 */
export default function payoutInvoiceArchiveModalData(Alpine) {
  Alpine.data("payoutInvoiceArchiveModalData", (url) => {
    return {
      loaded: false,
      // call archive endpoint
      async archivePayoutInvoice(url) {
        const formData = new FormData();
        formData.append("status", "archive");

        try {
          const response = await fetch(url, {
            method: "post",
            headers: {
              "X-CSRFToken": document.querySelector(
                "[name=csrfmiddlewaretoken]",
              ).value,
            },
            body: formData,
          });

          if (response.status !== 200)
            throw new Error("Error archiving invoice");
          Alpine.store("toasts").show(
            window.gettext("The submitted invoice has been deleted"),
          );
        } catch {
          Alpine.store("toasts").show(
            window.gettext("Error, the invoice cannot be archived"),
            "error",
          );
        }
        document.dispatchEvent(new Event("reload-invoices-event"));
      },
    };
  });
}
