export default function (Alpine) {
  Alpine.data("companySearch", (sirenInfo = {}, autofocus = false) => ({
    showList: false,
    searchedValue: "",
    selectedValue: sirenInfo,
    options: [],
    searchResults: [],
    focusedItem: null,
    processing: false,
    autofocus,
    async search(value) {
      const searchedValue = value || this.searchedValue;
      let response;
      this.processing = true;

      try {
        response = await fetch(
          `https://suggestions.pappers.fr/v2?q=${searchedValue}&cibles=nom_entreprise,siren`,
        );
      } catch (error) {
        this.processing = false;
        throw new Error("Pappers API not reachable");
      }
      if (response?.ok) {
        const { resultats_nom_entreprise = [], resultats_siren = [] } =
          await response.json();
        this.searchResults = [
          ...new Set([...resultats_nom_entreprise, ...resultats_siren]),
        ];
      } else {
        this.searchResults = [];
      }

      this.$nextTick(() => {
        this.processing = false;
      });
    },
    updateList() {
      this.options = this.searchResults
        .filter(({ nom_entreprise, date_creation, date_cessation }) => {
          return date_creation !== null && date_cessation === null;
        })
        .map(
          ({
            nom_entreprise,
            siren,
            siren_formate,
            date_creation,
            categorie_juridique,
            code_naf,
            siege,
          }) => {
            return {
              label: nom_entreprise || siren_formate,
              value: siren,
              formattedSiren: siren_formate,
              date_creation,
              categorie_juridique,
              naf_code: code_naf,
              hq_postcode: siege.code_postal,
            };
          },
        );

      this.showList = true;
    },
    validateMatches() {
      return (
        this.$refs.searchInput.value === this.$refs.searchHiddenMirror.value ||
        window.gettext(
          "Please select an option from the presented list to proceed",
        )
      );
    },
    selectItem(option) {
      this.$refs.searchInput.value = option.label;
      this.$refs.searchHiddenMirror.value = option.label;
      this.$refs.sirenHiddenField.value = option.value;

      if (this.$refs.legalCategoryHiddenField) {
        this.$refs.legalCategoryHiddenField.value = option.categorie_juridique;
      }

      if (this.$refs.nafcodeHiddenField) {
        this.$refs.nafcodeHiddenField.value = option.naf_code;
      }

      if (this.$refs.hqpostcodeHiddenField) {
        this.$refs.hqpostcodeHiddenField.value = option.hq_postcode;
      }

      if (this.$refs.incorporationDateField) {
        this.resetFieldValidity(this.$refs.incorporationDateField);
        this.$refs.incorporationDateField.value = option.date_creation;
      }

      this.selectedValue = option;
      this.showList = false;
      this.$dispatch("siren-selected", { sirenInfo: this.selectedValue });
      this.$refs.searchInput.dispatchEvent(
        new Event("change", { bubbles: true }),
      );
    },
    selectHelp() {
      this.showList = false;
      this.$dispatch("siren-help-selected");
      this.$dispatch("checkValidity");
    },
    async triggerSearch() {
      if (this.$refs.searchInput.hasAttribute("disabled")) return;
      const value = cleanIfSiren(this.$refs.searchInput.value.trim());
      if (value) {
        this.searchedValue = value;
        await this.search();
        this.updateList();
      }
    },
    searchInput: {
      "x-ref": "searchInput",
      autocomplete: "off",
      async "x-init"() {
        this.$refs.searchInput.placeholder = window.gettext("Name or SIREN");

        const companyRegistrationNumber = this.$refs.sirenHiddenField.value;
        const companyName = this.$refs.searchInput.value;

        if (this.autofocus && companyRegistrationNumber.length) {
          await this.search(companyRegistrationNumber);
          if (this.searchResults.length === 1) {
            const [{ nom_entreprise: label, siren: value }] =
              this.searchResults;
            this.selectItem({ label, value });
            return;
          }
          if (!this.searchResults.length) {
            await this.search(companyName);
          }
          this.updateList();
        }

        if (this.autofocus) {
          this.$refs.searchInput.focus();
        }
      },
      async "@click"() {
        this.triggerSearch();
      },
      async "@focusin"() {
        this.triggerSearch();
      },
      async "@input.debounce.200ms.stop.prevent"() {
        this.triggerSearch();
        if (!this.$refs.searchInput.value.length) {
          this.options = [];
        }
      },
      "@keyup.escape"() {
        this.showList = false;
      },
      "@keydown.enter.prevent"() {
        if (this.options[0]) this.selectItem(this.options[0]);
      },
    },
  }));
}
const isSiren = /^[\d\s-]*$/;
/**
 * Cleans the search value if it is a SIREN number
 * @param {string} value
 * @returns {string}
 */
const cleanIfSiren = (value) => {
  // If search is a SIREN, we want to remove spaces and dashes
  if (isSiren.test(value)) return value.replaceAll(/[\s-]/g, "");
  return value;
};
