export default function toastStore(Alpine) {
  let toastId = 0;
  // MUST be align with duration-xxxx used in x-transition attribute
  const transitionTiming = 500;

  Alpine.store("toasts", {
    queue: [],

    /* Initialize Toast Queue with any loading toasts from queryParams */
    init() {
      const params = new URLSearchParams(window.location.search);
      const toastParams = [
        "message",
        "type",
        "timeout",
        "dismissible",
        "hasCopyAction",
      ].map((key) => params.get(`toast.${key}`) ?? undefined);
      if (toastParams[0]) this.show(...toastParams);
    },

    /**
     * Add a toast to the queue, and show it
     * @param {String} message
     * @param {String} type = 'info | success | warning | error'
     * @param {Number} timeout = '0 | n' (milliseconds) 0 means permanent
     * @param {Boolean} dismissible
     * @param {Boolean} hasCopyAction
     */
    async show(
      message,
      type = "info",
      timeout = 5000,
      dismissible = true,
      hasCopyAction = false,
    ) {
      if (!message) {
        return console.error("ToastStore show method requires a message");
      }

      const toastData = Alpine.reactive({
        id: toastId++,
        message,
        type,
        show: false,
        timeout,
        timeoutId: null,
        dismissible,
        hasCopyAction,
      });

      this.queue.push(toastData);
      await Alpine.nextTick();
      toastData.show = true;
      this.hide(toastData);
      return toastData;
    },

    /**
     * empty toast queue if none is shown
     */
    clearQueue() {
      setTimeout(() => {
        this.queue = this.queue.filter(({ show }) => show === true);
      }, transitionTiming);
    },

    /**
     * Hide passed toast based on timeout
     * TODO: auto call hide in show if toast.timeout is provided
     */
    hide(toast) {
      if (toast.timeout > 0) {
        toast.timeoutId = setTimeout(() => {
          toast.show = false;
          this.clearQueue();
        }, toast.timeout);
      }
    },

    /**
     * Hide passed toast instantly
     */
    dismiss(toast) {
      clearTimeout(toast.timeoutId);
      toast.show = false;
      this.clearQueue();
    },
  });
}
