import Alpine from "alpinejs";
import htmx from "htmx.org";

import AlpinePlugins from "./alpine/plugins";
import AlpineDirective from "./alpine/directives";
import AlpineStores from "./alpine/stores";
import AlpineData from "./alpine/data";

import { checkSessionExpiration } from "./sessionController";
import { appEventHandlers } from "./app";

Alpine.plugin(AlpinePlugins);
Alpine.plugin(AlpineDirective);
Alpine.plugin(AlpineStores);
Alpine.plugin(AlpineData);

Object.assign(window, { Alpine, htmx });

Alpine.start();

appEventHandlers();
checkSessionExpiration();

if (!IS_DEV) {
  setTimeout(async () => {
    await window.$silvr.hubspot.prepareChat();
    setTimeout(window.$silvr.hubspot.loadChatWidget, 6000);
  }, 4000);
}
