export default function (Alpine) {
  Alpine.data("bankRedirect", () => ({
    /**
     * @type {Object}
     * @property {string} name
     * @property {string} icon
     * @property {string} institution_id
     * @property {string} redirectUrl
     * @property {string} [bank_id]
     * @property {boolean} [goDirectly]
     * @property {string} [psu_field_label]
     * @property {string} [psu_field_name]
     * @property {string} [use_embedded_auth]
     */
    get modalDetails() {
      return this.$modal.details ?? {};
    },
    abortable: true,
    async handleRedirect() {
      const details = this.modalDetails;

      if (!details.redirectUrl) return;

      // Allows request aborting
      const controller = new AbortController();
      const signal = controller.signal;
      this.$modal.addEventListener("close", () => controller.abort(), {
        once: true,
      });

      // delays redirect to give user time to cancel if they made a mistake
      if (details.goDirectly) {
        await sleep(2000);
        if (signal.aborted) return;
        this.abortable = false;
        window.location.href = details.redirectUrl;
        await sleep(2000);
        return;
      }

      try {
        const response = await fetch(this.modalDetails.redirectUrl, {
          signal,
        });

        if (response.ok) {
          const data = await response.json();
          await sleep(1000);
          if (signal.aborted) return;
          this.abortable = false;
          window.location.href = data.url;
          await sleep(2000);
          return;
        }

        if (details.psu_field_label) {
          const error =
            (await response.json()).error ??
            "An error has occurred, please try again later";
          this.$modal.open("yapily-request-summary-modal", {
            details: { ...details, error },
          });
          return;
        }
      } catch (e) {
        console.error(e);
      }
      if (signal.aborted) return;

      this.$modal.close();
      return this.$modal.open("bank-connection-error", {
        details: { bank_id: details.bank_id },
      });
    },
  }));
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
