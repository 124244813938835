import { hasProperty } from "./utils.js";

export const accordion = (Alpine) => {
  Alpine.magic("accordion", (el) => getAccordion(el, Alpine));

  Alpine.directive("accordion", (el, directive, utils) => {
    if (!directive.value) return handleAccordionRoot(el, directive, utils);
    if (directive.value === "group")
      return handleAccordionGroup(el, directive, utils);
    const accordionData = getAccordion(el, Alpine);
    if (!accordionData)
      return console.error(
        "Alpine Accordion: Element with directive:",
        directive.original,
        "is not inside an accordion root",
      );

    (accordionHandlers[directive.value] || failback)(
      accordionData,
      el,
      directive,
      utils,
    );
  }).before("bind");
};
export default accordion;

const groupMap = new WeakMap();
const accordionMap = new WeakMap();

const getGroup = (el, Alpine) => {
  const root = Alpine.findClosest(el, hasProperty("x-accordion:group"));
  return groupMap.get(root);
};

const getAccordion = (el, Alpine) => {
  const root = Alpine.findClosest(
    el,
    hasProperty("x-accordion", "x-accordion.open", "x-accordion.closed"),
  );
  return accordionMap.get(root);
};

const handleAccordionRoot = (
  el,
  { modifiers: [initialState = "closed"] },
  { Alpine },
) => {
  const accordionData =
    getGroup(el, Alpine)?.addAccordion(initialState === "open") ??
    Alpine.reactive({
      active: initialState === "open",
    });
  accordionMap.set(el, accordionData);
};

const handleAccordionGroup = (el, _, { Alpine, effect }) => {
  const groupData = Alpine.reactive({
    active: null,
    id: 0,
    addAccordion(initial) {
      const thisId = this.id++;
      const accordionInstance = Alpine.reactive({
        active: initial,
      });
      effect(() => {
        if (accordionInstance.active) {
          this.active = thisId;
        } else if (this.active === thisId) {
          this.active = null;
        }
      });
      effect(() => {
        if (this.active === thisId) {
          accordionInstance.active = true;
        } else {
          accordionInstance.active = false;
        }
      });
      return accordionInstance;
    },
  });
  groupMap.set(el, groupData);
};

const controlForce = {
  open: true,
  close: false,
};

const handleAccordionControl = (
  accordionData,
  el,
  { modifiers: [force] },
  { Alpine },
) => {
  Alpine.bind(el, {
    "x-on:click": () => {
      accordionData.active = controlForce[force] ?? !accordionData.active;
    },
    ":aria-expanded": () => accordionData.active,
  });
};
const handleAccordionContainer = (
  accordionData,
  el,
  { modifiers },
  { Alpine },
) => {
  Alpine.bind(el, {
    "x-show": () => accordionData.active,
  });
  Alpine.nextTick(() => {
    el.style.display = null;
  });
};

const accordionHandlers = {
  control: handleAccordionControl,
  container: handleAccordionContainer,
  group: handleAccordionGroup,
};

const failback = (_, el, directive) => {
  console.error(
    "Alpine Accordion: Invalid accordion directive",
    el,
    directive.original,
  );
};
