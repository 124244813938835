export default function teamMembers(Alpine) {
  Alpine.data("teamMembers", () => {
    const URL_PARAMS = new URL(window.location.href);
    return {
      type: URL_PARAMS.searchParams.get("type") || "ALL_ACCESS",
      status: URL_PARAMS.searchParams.get("status") || "ALL_STATUS",
      base_url() {
        return decodeURI(window.location.href.split("?")[0]);
      },
      reload_page() {
        const _url = this.url();
        if (_url !== decodeURI(window.location.href)) {
          window.location.assign(_url);
        }
      },
      url() {
        return `${this.base_url()}?type=${this.type}&status=${this.status}`;
      },
      sendInviteApiCall(email, role) {
        const payload = {
          email,
          role,
        };
        fetch(Alpine.store("globals").urls.addMember, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": document.querySelector("[name=csrfmiddlewaretoken]")
              .value,
          },
          body: JSON.stringify(payload),
        }).then((data) => {
          location.reload();
        });
      },
    };
  });
}
