export default function filterDropdown(Alpine) {
  Alpine.data("filterDropdown", ({ params }) => {
    const URL_PARAMS = new URLSearchParams(window.location.search);
    return {
      isOpen: false,
      selectedOption: {},
      param: params.name,
      init() {
        const filterParam = URL_PARAMS.get(this.param);

        if (filterParam) {
          this.selectedOption = params.options.find(
            ({ value }) => value === filterParam,
          );
        } else {
          [this.selectedOption] = params.options;
        }
      },
      onSelect({ value }) {
        this.isOpen = false;
        if (!value) {
          URL_PARAMS.delete(this.param);
        } else {
          URL_PARAMS.set(this.param, value);
        }

        // Reset pagination after each filter
        URL_PARAMS.delete("page");
        const redirectTo = new URL(window.location.href);
        redirectTo.search = URL_PARAMS.toString();
        window.location = redirectTo;
      },
    };
  });
}
