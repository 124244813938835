export default function (Alpine) {
  Alpine.data(
    "countrySelect",
    (defaultCountry = "FR", searchEnabled = true) => ({
      choice: null,
      init() {
        this.$refs.countrySelectRef.value = defaultCountry;
        this.$dispatch("change", { value: defaultCountry });
        import(/* webpackChunkName: "custom-select" */ "choices.js").then(
          (module) => {
            const CustomSelect = module.default;
            this.choice = new CustomSelect(this.$refs.countrySelectRef, {
              searchEnabled,
              itemSelectText: "",
              allowHTML: true,
              shouldSort: false,
              callbackOnCreateTemplates: this.createTemplate,
            });
          },
        );

        if (this.$refs.countrySelectRef.hasAttribute("disabled")) {
          this.choice.disable();
        }
      },
      createTemplate(template) {
        return {
          item: ({ classNames }, data) => {
            return template(`
          <div class="${classNames.item} ${
            data.highlighted
              ? classNames.highlightedState
              : classNames.itemSelectable
          } ${
            data.placeholder ? classNames.placeholder : ""
          } flex gap-4 items-center" data-item data-id="${data.id}" data-value="${
            data.value
          }" ${data.active ? 'aria-selected="true"' : ""} ${
            data.disabled ? 'aria-disabled="true"' : ""
          }>
            ${data.value.length ? `<span class="iti__flag iti__${data.value.toLowerCase()}"></span>` : ""} <span class="truncate">${data.label}</span>
          </div>
        `);
          },
          choice: ({ classNames }, data) => {
            return template(`
          <div class="${classNames.item} ${classNames.itemChoice} ${
            data.disabled ? classNames.itemDisabled : classNames.itemSelectable
          } ${data.selected ? "choices__item--selected" : ""} flex gap-4 items-center" data-select-text="${
            this.config.itemSelectText
          }" data-choice ${
            data.disabled
              ? 'data-choice-disabled aria-disabled="true"'
              : "data-choice-selectable"
          } data-id="${data.id}" data-value="${data.value}" ${
            data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
          }>
            ${data.value.length ? `<span class="iti__flag iti__${data.value.toLowerCase()}"></span>` : ""} <span class="truncate">${data.label}</span>
          </div>
        `);
          },
        };
      },
    }),
  );
}
