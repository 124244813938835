const formValidity = (el) => {
  const inputErrorClasses = "input--text-error";

  const constraintsMsg = [
    {
      state: "valueMissing",
      type: "text",
      msg: window.gettext("This field is required"),
    },
    {
      state: "valueMissing",
      type: "select-one",
      msg: window.gettext("Please select an item from the list"),
    },
    {
      state: "typeMismatch",
      type: "url",
      msg: window.gettext("Please enter a valid web address"),
    },
    {
      state: "typeMismatch",
      type: "email",
      msg: window.gettext("Please enter a valid email address"),
    },
    {
      state: "badInput",
      type: "number",
      msg: window.gettext("Please enter a number"),
    },
    {
      state: "tooShort",
      type: "text",
      msg: window.gettext("Please enter a longer value"),
    },
    {
      state: "patternMismatch",
      type: "text",
      msg: null,
    },
  ];

  return {
    isFormValid: false,
    messages: {},
    formData: new FormData(el),
    get formElements() {
      return Array.from(this.$refs.form.elements).filter(
        (el) => el.willValidate && !(el instanceof HTMLButtonElement),
      );
    },
    init() {
      this.$refs.form.setAttribute("novalidate", true);
      this.formElements.forEach((el) => {
        el.touched = false;
        el.blurred = false;
      });
      this.$nextTick(() => {
        this.isFormValid = this.$refs.form.checkValidity();
      });
    },
    checkValidity({ type, target }) {
      this.formData = new FormData(this.$refs.form);
      let stylesTarget = target;
      // Choices.js selects case
      if (target.classList.contains("choices")) {
        stylesTarget = target.querySelector(".choices__inner");
        target = target.querySelector("select");
      }

      if (target.touched && type === "focusout") {
        target.blurred = true;
      }

      this.checkField({ input: target, stylesTarget });
      this.$nextTick(() => {
        this.isFormValid = this.$refs.form.checkValidity();
      });
    },
    checkField({ input, stylesTarget = input, force = false }) {
      // used to avoid non input elements triggered by @focusout
      if (!this.formElements.includes(input)) return;

      // We collect this info here, because when we check radio grouped inputs we store errors on the first radio
      const touched = input.touched;

      // In case of radio inputs (grouped by name) we store errors on the first radio.
      if (input.type === "radio") {
        input = this.formElements.find((el) => el.name === input.name);
      }

      // avoid to check untouched input
      if (!force && !touched) {
        return "untouched";
      }

      let hasError = false;
      input.setCustomValidity("");
      this.messages[input.name] = "";

      // We check for advanced validation
      const customValidation = this.$validate(input);
      hasError = !customValidation.valid;

      if (hasError) {
        input.setCustomValidity(customValidation.message);
        this.messages[input.name] = customValidation.message;
      } else {
        // We check for native validation
        if (force || input.blurred) {
          input.setCustomValidity("");
          const currentError = this.getMsgError(input);
          hasError = currentError.length > 0;
          if (hasError) {
            input.setCustomValidity(currentError);
            this.messages[input.name] = currentError;
          }
        }
      }

      // We update styles
      stylesTarget.classList.toggle("input--text-error", hasError);
      return hasError;
    },
    checkFields() {
      this.formElements.forEach((element) => {
        const hasError = this.checkField({ input: element, force: true });
        if (hasError) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      });
    },
    getMsgError(input) {
      if (input.validity.valid) {
        return "";
      }

      const { type, dataset } = input;
      const state = this.getInputValidityState(input);
      const constraint = constraintsMsg.find(
        (c) => (c.type === null || c.type === type) && c.state === state,
      )?.msg;

      return constraint || dataset.customError || input.validationMessage;
    },
    getInputValidityState(input) {
      for (const state in input.validity) {
        if (input.validity[state] === true) {
          return state;
        }
      }
    },
    formValidityEvents: {
      "@change"(ev) {
        ev.target.touched = true;
        this.checkValidity(ev);
      },
      "@input"(ev) {
        ev.target.touched = true;
        this.checkValidity(ev);
      },
      "@focusout"(ev) {
        if (
          !["radio", "checkbox"].includes(ev.target.type) &&
          ev.target.touched
        ) {
          this.checkValidity(ev);
        }
      },
      "@htmx:afterSettle"() {
        this.$nextTick(() => {
          this.isFormValid = this.$refs.form.checkValidity();
        });
      },
      "@checkValidity"(ev) {
        this.checkValidity(ev);
      },
      "@submit"(ev) {
        const submitter = ev.submitter || document.activeElement;
        if (submitter.hasAttribute("formnovalidate")) {
          return;
        }
        if (!this.isFormValid) {
          ev.preventDefault();
          this.checkFields();
        }
      },
    },
    resetFieldValidity(target) {
      target = this.formElements.find((el) => el.name === target?.name);
      if (!target) return;

      target.setCustomValidity("");
      target.classList.remove(inputErrorClasses);
      this.messages[target.name] = "";
      target.blurred = false;
    },
    resetValidity() {
      this.formElements.forEach((element) => {
        this.resetFieldValidity(element);
      });
      this.$refs.form.reset();
    },
  };
};

export default function (Alpine) {
  Alpine.data("formValidity", formValidity);
}
