import modalData from "./connector_modals";

export default function connectionModal(Alpine) {
  Alpine.data("connectionModal", makeModalObject);
}

function makeModalObject(integrationName) {
  if (!integrationName) {
    throw new Error("Connection Modal Error: No integration name provided");
  }
  if (!modalData[integrationName]) {
    throw new Error(
      `Connection Modal Error: No modal data for ${integrationName}. Please check for typos.`,
    );
  }
  const baseObject = {
    isProcessing: false,
    isInvalid: false,
    error: null,
    async makeConnection() {
      this.isProcessing = true;
      const connectResponse = await connect(
        this.url,
        this.payload,
        this.$dispatch,
        this.callback,
        this.showToast,
      );
      const isSuccessful = connectResponse.isSuccessful;
      this.isProcessing = false;
      this.isInvalid = !isSuccessful;
      this.isConnectorsOpen = !isSuccessful;
      this.error = connectResponse.error;
      if (isSuccessful) this.$modal.close();
    },
  };
  return Object.defineProperties(
    baseObject,
    Object.getOwnPropertyDescriptors(modalData[integrationName]()),
  );
}

async function connect(
  url,
  payload,
  $dispatch,
  callback = async () => {},
  showToast,
) {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": document.querySelector("[name=csrfmiddlewaretoken]")
          .value,
      },
      body: JSON.stringify(payload),
    });

    if (response.status !== 200) {
      let errorPayload = null;
      try {
        errorPayload = await response.json();
      } catch (e) {
        // unexpected format of the error response
        window.$silvr.utils.captureException(e);
      }

      // we check the format of the error to be sure this is a string and not an object
      // if the format is not correct we use an empty message so we will fallback on a default HTML message
      const message =
        typeof errorPayload?.error === "string" ? errorPayload.error : "";
      const code = errorPayload?.code ?? 0;

      return {
        isSuccessful: false,
        error: { message, code },
      };
    }
    await callback(response);
    $dispatch("refresh-integrations");
    if (showToast) {
      $dispatch("show-toast");
    }
    return { isSuccessful: true };
  } catch (e) {
    // Unexpected error
    window.$silvr.utils.captureException(e);
    const message = "";
    const code = 0;
    return {
      isSuccessful: false,
      error: { message, code },
    };
  }
}
