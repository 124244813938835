const validators = new WeakMap();

export default function validate(Alpine) {
  Alpine.directive("validate", (el, { expression }, { evaluateLater }) => {
    validators.set(el, evaluateLater(expression));
  });
  Alpine.magic("validate", () => (el) => {
    const validator = validators.get(el);
    if (!validator) return { valid: true };
    let output;
    validator((result) => {
      output = result;
    });
    if (typeof output === "boolean") return { valid: output };
    if (typeof output === "string") return { valid: false, message: output };
    return output;
  });
}
