export default function (Alpine) {
  Alpine.data("searchSelect", (searchEnabled = true) => ({
    choice: null,
    init() {
      import(/* webpackChunkName: "custom-select" */ "choices.js").then(
        ({ default: CustomSelect }) => {
          this.choice = new CustomSelect(this.$refs.searchSelectRef, {
            searchEnabled,
            itemSelectText: "",
            allowHTML: true,
            shouldSort: false,
            callbackOnCreateTemplates: this.createTemplate,
          });
        },
      );

      if (this.$refs.searchSelectRef.hasAttribute("disabled")) {
        this.choice.disable();
      }
    },
    /**
     * Handles [upstream bug](https://github.com/Choices-js/Choices/issues/613)
     * Without this, it's possible (and easy) to end up with broken searches
     * caused by non-character keys injecting their names into the search
     **/
    interceptEvents(event) {
      const { choice } = this;
      if (event instanceof UIEvent) {
        choice.showDropdown();
      } else if (
        event instanceof KeyboardEvent &&
        !choice.dropdown.isActive &&
        !["tab", "shift", "meta", "ctrl", "enter"].includes(
          event.key.toLowerCase(),
        )
      ) {
        this.choice.showDropdown();
        if (event.key.length > 1) event.stopImmediatePropagation();
      }
    },
    createTemplate(template) {
      return {
        item: ({ classNames }, data) => {
          return template(`
            <div class="${classNames.item} ${
              data.highlighted
                ? classNames.highlightedState
                : classNames.itemSelectable
            } ${
              data.placeholder ? classNames.placeholder : ""
            } flex gap-4 items-center" data-item data-id="${
              data.id
            }" data-value="${data.value}" ${
              data.active ? 'aria-selected="true"' : ""
            } ${data.disabled ? 'aria-disabled="true"' : ""}>
              <span class="truncate">${data.label}</span>
            </div>
          `);
        },
        choice: ({ classNames }, data) => {
          return template(`
            <div class="${classNames.item} ${classNames.itemChoice} ${
              data.disabled
                ? classNames.itemDisabled
                : classNames.itemSelectable
            } ${
              data.selected ? "choices__item--selected" : ""
            } flex gap-4 items-center" data-select-text="${
              this.config.itemSelectText
            }" data-choice ${
              data.disabled
                ? 'data-choice-disabled aria-disabled="true"'
                : "data-choice-selectable"
            } data-id="${data.id}" data-value="${data.value}" ${
              data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
            }>
              <span class="truncate">${data.label}</span>
            </div>
          `);
        },
      };
    },
  }));
}
