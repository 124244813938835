/**
 * Shopify modal AlpineJS x-data
 */
function shopify() {
  return {
    subdomain: "",
    buttonTxt: window.gettext("Add"),
    url: "/integrations/shopify/authenticate-public",
    get payload() {
      return {
        subdomain: `${this.subdomain}.myshopify.com`,
      };
    },
    async callback(response) {
      const { redirect_to } = await response.json();
      if (redirect_to) window.location.replace(redirect_to);
    },
    showToast: false,
  };
}

export default shopify;
