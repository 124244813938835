import {
  formatCurrency,
  getMonthlyRepayment,
  getTotalCommission,
  getCommissionPercentage,
} from "../../utils.js";

export default function (Alpine) {
  Alpine.data("formOffer", (expectedInterestRate = 0, locale = "fr") => ({
    fundingAmount: null,
    loanDuration: null,
    offerPayout: null,
    expectedInterestRate,
    locale,
    get totalCommission() {
      if (!this.offerPayout) return 0;

      return formatCurrency(
        getTotalCommission(
          Number(this.fundingAmount),
          getCommissionPercentage(
            Number(this.loanDuration),
            new Date(this.offerPayout),
            this.expectedInterestRate,
          ),
        ),
        this.locale,
      );
    },
    get installment() {
      if (!this.offerPayout) return 0;

      return formatCurrency(
        getMonthlyRepayment(
          Number(this.fundingAmount),
          getCommissionPercentage(
            Number(this.loanDuration),
            new Date(this.offerPayout),
            this.expectedInterestRate,
          ),
          Number(this.loanDuration),
        ),
        this.locale,
      );
    },
  }));
}
