export default function (Alpine) {
  let disableIntervalID;
  let activeUserCheckIntervalID;
  Alpine.data(
    "mailValidationForm",
    (userIsActiveUrl, dispatchSignupStepUrl, expiration = 30) => ({
      disabled: false,
      remaining: null,
      expiration,
      init() {
        this.pageLoadedTime = Date.now();
        this.checkIfUserIsActive();
      },
      async checkIfUserIsActive() {
        const response = await fetch(userIsActiveUrl);
        if (response.ok) {
          const { isActive } = await response.json();
          if (isActive) {
            window.location.replace(dispatchSignupStepUrl);
          } else {
            activeUserCheckIntervalID = setTimeout(
              () => this.checkIfUserIsActive(),
              this.activeCheckFrequency(),
            );
          }
        } else {
          clearTimeout(activeUserCheckIntervalID);
        }
      },
      activeCheckFrequency() {
        const minutesSinceLoad =
          (Date.now() - this.pageLoadedTime) / (60 * 1000);
        let seconds;
        if (minutesSinceLoad < 10) {
          seconds = 2;
        } else if (minutesSinceLoad < 30) {
          seconds = 60;
        } else if (minutesSinceLoad < 60) {
          seconds = 2 * 60;
        } else {
          seconds = 5 * 60;
        }
        return seconds * 1000;
      },
      async submitForm() {
        this.temporaryDisable(expiration);
        const form = this.$el;
        await fetch(form.action, {
          method: form.method,
          body: new FormData(form),
        });
        this.$store.toasts.show(
          window.gettext("We've sent another verification email."),
        );
        clearTimeout(activeUserCheckIntervalID);
        this.pageLoadedTime = Date.now();
        activeUserCheckIntervalID = setTimeout(
          () => this.checkIfUserIsActive(),
          this.activeCheckFrequency(),
        );
      },
      temporaryDisable() {
        this.disabled = true;
        this.remaining = this.expiration;
        disableIntervalID = setInterval(() => {
          this.remaining -= 1;
          if (!this.remaining) {
            clearInterval(disableIntervalID);
            this.disabled = false;
          }
        }, 1000);
      },
      submitBtn: {
        ":disabled"() {
          return this.disabled;
        },
      },
      destroy() {
        clearTimeout(activeUserCheckIntervalID);
        clearInterval(disableIntervalID);
      },
    }),
  );
}
