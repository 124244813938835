export default function (Alpine) {
  const extensions = {
    "image/png": "png",
    "image/jpeg": "jpeg",
  };

  Alpine.data("avatarCrop", (endpoint, csrfToken) => ({
    crop: null,
    previewImage: null,
    init() {
      /*
      since we x-teleport this element, x-refs can't be use.
      see: https://github.com/alpinejs/alpine/discussions/2494
      */
      this.$nextTick(() => {
        this.previewImage = document.getElementById("avatar-cropper-preview");
      });
    },
    /**
     * Setup crop instance
     */
    preview() {
      const fileInput = this.$refs.image_input;
      const reader = new FileReader();
      reader.readAsDataURL(fileInput.files[0]);

      reader.onload = () => {
        this.$modal.open("avatar-cropper");
        /* This handle the case where the user as closed the modal clicking on the dropdown or esc key which do not trigger this.clear() */
        if (this.crop) {
          this.crop.destroy();
        }

        this.previewImage.src = reader.result;
        import(/* webpackChunkName: "image-cropper" */ "cropperjs").then(
          (module) => {
            const Cropper = module.default;
            this.crop = new Cropper(this.previewImage, {
              aspectRatio: 1 / 1,
              background: false,
              guides: false,
              modal: true,
              scalable: true,
              viewMode: 0,
              minContainerWidth: 350,
              minContainerHeight: 350,
            });
          },
        );
      };
    },

    /**
     * Get form object with file attribute.
     */
    getFormData() {
      return new Promise((resolve) => {
        this.crop
          .getCroppedCanvas({
            maxWidth: 1000,
            maxHeight: 1000,
            fillColor: "#fff",
          })
          .toBlob(async (blob) => {
            const formData = new FormData();
            const extension = extensions[blob.type];
            formData.append(
              "avatar",
              blob,
              `image-${btoa(Math.random()).substr(10, 8)}.${extension}`,
            );
            resolve(formData);
          });
      });
    },

    /**
     * Call Silvr endpoint to accept formdata and
     * store profile picture.
     */
    async storeAvatar() {
      const formData = await this.getFormData();
      formData.append("csrfmiddlewaretoken", csrfToken);
      const request = await fetch(endpoint, {
        method: "POST",
        body: formData,
      });

      const data = await request.json();
      if (!data.url) {
        console.error(data);
      }
      document.querySelectorAll(".js-user_avatar").forEach((tag) => {
        tag.src = data.url;
      });

      this.clear();
    },

    clear() {
      this.crop.destroy();

      this.$nextTick(() => {
        this.$modal.close();
      });
    },
  }));
}
