export default function (Alpine) {
  Alpine.data(
    "bankSelector",
    ({
      selectedBankId = [],
      connectedBankId = [],
      countryCode = "",
      endpoint = "",
    }) => ({
      filter: {
        q: "",
        country_code: countryCode,
        page: 1,
      },
      selectedBankId,
      connectedBankId,
      inProgress: false,
      atEnd: false,
      bankList: [],
      effects: [],
      formSubmitted: false,
      init() {
        this.filter.q = this.$refs.searchInputRef.value;
        this.effects.push(Alpine.effect(() => this.fetchBanks()));
      },
      destroy() {
        this.effects.forEach(Alpine.release);
      },
      get isFormValid() {
        return !!this.selectedBankId.length;
      },
      async fetchBanks() {
        if (this.atEnd) return;
        this.inProgress = true;
        const target = new URL(endpoint, window.location);
        target.search = new URLSearchParams(this.filter);
        const {
          at_end: atEnd,
          results,
          country_code: countryCode,
          q,
          page,
        } = await fetchBanksOr(target, {});
        if (q !== this.filter.q || countryCode !== this.filter.country_code) {
          return;
        }
        this.atEnd = atEnd;
        if (page === this.filter.page) this.inProgress = false;
        if (page > 1) this.bankList[page - 1] = results;
        else this.bankList = [results];
      },
      get banks() {
        return this.bankList.flat();
      },
      update(type, value) {
        this.filter[type] = window.$silvr.utils.normalizeString(value.trim());
        this.filter.page = 1;
        this.atEnd = false;
      },
      formBindings: {
        "@submit"(ev) {
          this.formSubmitted = true;

          if (!this.isFormValid) {
            ev.preventDefault();
          }
        },
      },
    }),
  );
}

const fetchBanksOr = async (target, defaultReturn) => {
  try {
    const req = await fetch(target);
    return await req.json();
  } catch (e) {
    console.error(e);
  }
  return defaultReturn;
};
