export default function (Alpine) {
  Alpine.data(
    "formCompany",
    ({
      currentCountry = null,
      legalForm = null,
      companyName = null,
      pappersCompany = { value: "", label: "" },
      businessAvailableCountries = [],
      handelsregisterLegalForms = [],
      steuernummerLegalForms = [],
    }) => ({
      currentCountry,
      companyName,
      legalForm,
      pappersCompany,
      incorporationDate: null,
      businessAvailableCountries,
      handelsregisterLegalForms,
      steuernummerLegalForms,
      get isFrench() {
        return this.currentCountry === "FR";
      },
      get isGerman() {
        return this.currentCountry === "DE";
      },
      get isHandelsregisterLegalForm() {
        return this.handelsregisterLegalForms.includes(this.legalForm);
      },
      get isSteuernummerLegalForm() {
        return this.steuernummerLegalForms.includes(this.legalForm);
      },
      get isCountryBusinessAvailable() {
        return this.businessAvailableCountries.includes(this.currentCountry);
      },
      formatDate(locale) {
        const [year, month, day] = this.pappersCompany.date_creation.split("-");
        return new Date(year, month - 1, day).toLocaleDateString(locale);
      },
      form: {
        "@siren-selected"({ detail }) {
          this.pappersCompany = detail.sirenInfo;
        },
        "@country-select-change"(event) {
          this.pappersCompany.formattedSiren = "";
          this.pappersCompany.date_creation = "";
        },
      },
    }),
  );
}
