/**
 * Usage examples:
 * - minimal (by default triggered on click):
 *   <button x-track="{eventName: 'An event name'}">Some button</button>
 *
 * - with additional properties:
 *   <button x-track="{eventName: 'An event name', property1: 'value1', property2: 'value2'}">Some button</button>
 *
 * - with a specific event:
 *   <input x-track.input="{eventName : 'input event', value: $el.value}" />
 *
 * - with multiple events:
 *   <input x-track.input.click="{eventName : 'input event', value: $el.value}" />
 */

export default function (Alpine) {
  /**
   * Segment tracking AlpineJS directive
   * @param {HTMLElement} el - The HTMLelement the directive is on
   * @param {Event} param.modifiers - The Event that triggered tracking
   * @param {String} param.expression - a valid JSON string representing additional properties to track
   */
  const track = (el, { modifiers, expression }, { evaluateLater, cleanup }) => {
    const getTrackingProps = evaluateLater(expression);

    /**
     * Get data from backend passed to any event
     */

    let segmentPageProps = {};
    let layerData = {};

    try {
      segmentPageProps = window.$silvr.utils.loadJsonScript({
        selector: "#segment_page_properties",
      });
    } catch (error) {
      if (process.env.NODE_ENV === "production") {
        console.info("segment_page_properties object is missing", error);
      }
    }

    try {
      layerData = window.$silvr.utils.loadJsonScript({
        selector: "#layerData",
      });
    } catch (error) {
      console.info("layerData object is missing", error);
    }

    const commonProperties = {
      ...layerData,
      ...segmentPageProps,
    };

    modifiers = modifiers.length ? modifiers : ["click"];

    const eventHandler = ({ type }) => {
      getTrackingProps(({ eventName, ...eventPropsRest }) => {
        window.analytics?.track(eventName, {
          ...commonProperties,
          ...eventPropsRest,
        });

        logTracking({ eventName, type, commonProperties, eventPropsRest });
      });
    };

    // special case for links, introducing 300ms delay to allow for tracking to be sent
    if (el instanceof HTMLAnchorElement) {
      getTrackingProps(({ eventName, ...eventPropsRest }) => {
        window.analytics?.trackLink(el, eventName, () => {
          logTracking({
            eventName,
            type: "trackLink",
            commonProperties,
            eventPropsRest,
          });

          return {
            ...commonProperties,
            ...eventPropsRest,
          };
        });
      });
    } else {
      modifiers.forEach((event) => {
        el.addEventListener(event, eventHandler);
      });
    }

    cleanup(() => {
      modifiers.forEach((event) => {
        el.removeEventListener(event, eventHandler);
      });
    });
  };

  const logTracking = ({
    eventName,
    type,
    commonProperties,
    eventPropsRest,
  }) => {
    if (process.env.NODE_ENV !== "production") {
      console.info(`Tracking event "${eventName}" sent on "${type}"`, {
        ...commonProperties,
        ...eventPropsRest,
      });
    }
  };

  Alpine.directive("track", track);
}
