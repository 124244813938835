export default function (Alpine) {
  Alpine.data("dropdown", () => ({
    opened: false,
    fixed: true,
    wrapper: {
      "@keydown.escape"() {
        this.opened = false;
      },
    },
    backdrop: {
      "x-show"() {
        return this.opened;
      },
      "x-transition.opacity": "",
    },
    trigger: {
      "@click"() {
        this.opened = true;
      },
    },
    list: {
      "x-show"() {
        return this.opened;
      },
      "@click"() {
        this.opened = false;
      },
      "@click.outside"() {
        this.opened = false;
      },
      "x-transition:enter=": "transition ease-out duration-100",
      "x-transition:enter-start": "transform opacity-0 scale-95",
      "x-transition:enter-end": "transform opacity-100 scale-100",
      "x-transition:leave": "transition ease-in duration-75",
      "x-transition:leave-start": "transform opacity-100 scale-100",
      "x-transition:leave-end": "transform opacity-0 scale-95",
      ":aria-hidden"() {
        return !this.opened;
      },
      ":tabindex"() {
        return this.opened ? "" : -1;
      },
    },
  }));
}
