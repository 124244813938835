export default function updateMember(Alpine) {
  Alpine.data("updateMember", () => ({
    role: null,
    email: null,
    init() {
      this.$modal.addEventListener("open-modal", ({ detail }) => {
        if (detail.id === "modify-member-modal") {
          this.role = this.$modal.details.role;
          this.email = this.$modal.details.email;
        }
      });
    },
    async updateUserAccess({ uuid }) {
      try {
        await fetch(Alpine.store("globals").urls.updateMember, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": document.querySelector("[name=csrfmiddlewaretoken]")
              .value,
          },
          body: JSON.stringify({ uuid, role: this.role }),
        });
      } finally {
        location.reload();
      }
    },
  }));
}
