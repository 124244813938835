export default function modal(Alpine) {
  Alpine.data("webcallback", (fallbackModalId = "webcallback-modal") => {
    let htmlEl;
    return {
      ready: null,
      opened: false,
      openCta: null,
      closeCta: null,
      init() {
        htmlEl = document.documentElement;
      },
      /**
       * Used as callback of the optico:init event
       * Sets the ready state based on optico open hours configuration
       * @param {Event} detail
       * @param {boolean} detail.enable
       */
      initOptico({ detail }) {
        if (detail.enable) {
          this.openCta = document.getElementById("optico_call_me_back_button");
          this.closeCta = document.getElementById("optico_call_me_back_cancel");
          this.ready = true;
        } else {
          this.ready = false;
        }
      },
      /**
       * Try to open the optico modal, or `fallbackModalId` modal#id as fallback
       */
      openModal() {
        if (this.ready === true) {
          const closeBackrop = ({ target }) => {
            if (target?.id === "optico_call_me_back_overlay") {
              this.closeCta.click();
              this.opened = false;
              htmlEl.removeEventListener("click", closeBackrop);
            }
          };

          this.openCta.click();
          htmlEl.addEventListener("click", closeBackrop);

          return;
        }

        this.$modal.open(fallbackModalId);
      },
    };
  });
}
