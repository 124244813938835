/**
 * This is a utility Data for combining multiple Alpine Data components
 * @param {AlpineComponent[]}  components - component objects to combine
 *
 * Passed in components can be component factory function or component object
 * @example
 * x-data="combine(componentOne, componentTwo('param1', 'param2'))"
 */
export default function combine(Alpine) {
  Alpine.data("combine", (...components) =>
    components.reduce((acc, comp) =>
      Object.defineProperties(
        acc,
        Object.getOwnPropertyDescriptors(
          typeof comp === "function" ? comp() : comp,
        ),
      ),
    ),
  );
}
