export default function (Alpine) {
  /**
   * @param {string} value - Initial date value as string, i.e. '2024-12-31'
   * @param {string} minDate - Minimum date that can be selected value as string, i.e. '2024-12-31'
   * @param {string} maxDate - Maximum date that can be selected value as string, i.e. '2024-12-31'
   * @param {string} locale - Locale to use for date formatting
   */
  Alpine.data("datepicker", ({ date, minDate, maxDate }, locale = "fr") => ({
    date,
    minDate,
    maxDate,
    month: null,
    year: null,
    showNextMonth: true,
    showPrevMonth: true,
    init() {
      this.date = date ? new Date(date) : new Date();
      this.date.setHours(0, 0, 0, 0);
      this.minDate = minDate ? new Date(minDate) : new Date(0);
      this.minDate.setHours(0, 0, 0, 0);
      this.maxDate = maxDate ? new Date(maxDate) : new Date(9999, 11, 31);
      this.maxDate.setHours(0, 0, 0, 0);
      this.month = this.date.getMonth();
      this.year = this.date.getFullYear();
      this.setNavVisibility();
    },
    get monthName() {
      return new Date(this.year, this.month, 1).toLocaleString(locale, {
        month: "long",
      });
    },
    get isoDate() {
      const year = this.date.getFullYear();
      const month = String(this.date.getMonth() + 1).padStart(2, "0");
      const day = String(this.date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    get localizedDate() {
      return this.date.toLocaleString(locale, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });
    },
    changeMonth(delta = 0) {
      const nextDate = new Date(this.year, this.month, 1);
      nextDate.setMonth(nextDate.getMonth() + delta);

      const inRangeMin =
        nextDate.getMonth() >= this.minDate.getMonth() &&
        nextDate.getFullYear() >= this.minDate.getFullYear();
      const inRangeMax =
        nextDate.getMonth() <= this.maxDate.getMonth() &&
        nextDate.getFullYear() <= this.maxDate.getFullYear();

      if (inRangeMin && inRangeMax) {
        this.month = nextDate.getMonth();
        this.year = nextDate.getFullYear();
        this.setNavVisibility(delta);
      }
    },
    setNavVisibility(delta = 0) {
      const nextMonth = new Date(this.year, this.month, 1);
      nextMonth.setMonth(nextMonth.getMonth() + delta);
      this.showNextMonth = nextMonth <= this.maxDate;
      this.showPrevMonth = nextMonth >= this.minDate;
    },
    weekdayNames() {
      return Array.from({ length: 7 }, (_, i) => {
        const day = new Date(0, 0, i + 1);
        return day.toLocaleString(locale, { weekday: "short" });
      });
    },
    selectDate(day) {
      this.date = day.date;
      this.month = this.date.getMonth();
      this.year = this.date.getFullYear();
      this.$dispatch("date-selected", day.date);
    },
    get drawMonthDays() {
      const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();
      const firstDayIndex = new Date(this.year, this.month, 1).getDay();
      const lastDayIndex = new Date(
        this.year,
        this.month,
        daysInMonth,
      ).getDay();

      const days = Array.from({ length: daysInMonth }, (_, i) => ({
        date: new Date(this.year, this.month, i + 1),
      }));

      const emptyDaysStart = Array.from(
        { length: (firstDayIndex + 6) % 7 },
        (_, i) => ({
          date: new Date(this.year, this.month, 1 - firstDayIndex + i),
        }),
      );

      const emptyDaysEnd = Array.from(
        { length: (6 - lastDayIndex + 7) % 7 },
        (_, i) => ({
          date: new Date(this.year, this.month, daysInMonth + i + 1),
        }),
      );

      return [...emptyDaysStart, ...days, ...emptyDaysEnd].map((day, index) => {
        const isWeekday = ![0, 6].includes(day.date.getDay());
        const isSelectable =
          day.date >= this.minDate && day.date <= this.maxDate && isWeekday;
        const isToday = day.date.toDateString() === new Date().toDateString();
        const isSelected =
          isSelectable && day.date.toDateString() === this.date.toDateString();

        return {
          ...day,
          index,
          isWeekday,
          isSelectable,
          isToday,
          isSelected,
        };
      });
    },
  }));
}
