// tells HTMX to refresh the file table, and adds a fade animation to the new row
import { sizeAsString } from "../../utils";
function refreshFileTable(invoiceId) {
  const animateNewFile = async () => {
    const fileToAnimate = document.querySelector(`tr[key="${invoiceId}"]`);
    if (!fileToAnimate) return;
    fileToAnimate.classList.add(
      "opacity-0",
      "duration-1000",
      "transition-opacity",
    );
    document.addEventListener(
      "htmx:afterSettle",
      () => fileToAnimate.classList.remove("opacity-0"),
      { once: true },
    );
    document.removeEventListener("htmx:afterSwap", animateNewFile);
  };
  document.addEventListener("htmx:afterSwap", animateNewFile);
  document.dispatchEvent(new Event("reload-invoices-event"));
}

/**
 * Upload file to silvr /invoice/upload endpoint
 * @param {*} file
 * @returns bool
 */
async function uploadFile(file, signal) {
  const formData = new FormData();
  formData.append("document_file", file.file);
  const url = "/payout/invoices/upload";
  try {
    const response = await fetch(url, {
      method: "post",
      headers: {
        "X-CSRFToken": document.querySelector("[name=csrfmiddlewaretoken]")
          .value,
      },
      body: formData,
      signal,
    });

    if (response.status !== 201) {
      throw new Error(window.gettext("Error uploading file"));
    }

    //
    const { created: invoiceId } = await response.json();
    refreshFileTable(invoiceId);

    return true;
  } catch (e) {
    console.log(`error uploading ${file.filename}:`, e);
    return !!e.message.includes("user aborted");
  }
}

function getFileDetails(file) {
  const namePieces = file.name.split(".");
  const [filename, ext] = [
    namePieces[0],
    namePieces[namePieces.length - 1].toLowerCase(),
  ];
  return {
    filename,
    ext,
    filesizeString: sizeAsString(file.size),
    epoch: Date.now(),
  };
}

let COUNTER = 1;
function fileDefaults() {
  return {
    id: COUNTER++,
    uploading: false,
    uploaded: false,
  };
}

const ALLOWED_EXTENSIONS = ["pdf"];
const FILESIZE_LIMIT = 15 * 1024 ** 2;
function processFiles(fileList) {
  const detailsArray = Array.from(fileList).map((file) => ({
    ...fileDefaults(),
    ...getFileDetails(file),
    file,
  }));
  return detailsArray.reduce(
    (status, file) => {
      const action =
        !ALLOWED_EXTENSIONS.includes(file.ext) ||
        file.file.size > FILESIZE_LIMIT
          ? "error"
          : "ready";
      status[action].push(file);
      return status;
    },
    { ready: [], error: [] },
  );
}

export default function invoiceUploader(Alpine) {
  Alpine.store("files", []);
  Alpine.data("invoiceUploader", () => {
    const effects = [];
    return {
      files: [],
      fileErrors: [],
      uploading: false,
      dragging: false,
      errorType: "maxsize",
      controller: undefined,
      get currentFile() {
        return this.files.find((file) => file.uploading);
      },
      get nextFile() {
        return this.files.find((file) => !file.uploaded && !file.uploading);
      },
      get totalFilesCount() {
        return this.files.length;
      },
      get uploadedFilesCount() {
        return this.totalFilesCount - this.remainingFilesCount;
      },
      get remainingFilesCount() {
        return this.files.filter((file) => !file.uploaded && !file.uploading)
          .length;
      },
      init() {
        effects.push(
          Alpine.effect(() => {
            if (this.fileErrors.length) {
              this.$modal.open("upload-error");
            }
          }),
        );
        effects.push(
          Alpine.effect(() => {
            this.uploadQueue();
          }),
        );
      },
      destroy() {
        effects.forEach(Alpine.release);
      },
      abort() {
        this.files = [];
        this.controller?.abort();
      },
      handleFileInput(e) {
        this.addFiles(processFiles(e.target.files));
      },
      handleDrop(e) {
        this.addFiles(processFiles(e.dataTransfer.files));
        this.dragging = false;
      },
      addFiles({ ready, error }) {
        this.files.push(...ready);
        this.errorType = "client";
        this.fileErrors.push(...error);
      },
      uploadedToastMessage(successCount) {
        if (successCount > 0) {
          // https://docs.djangoproject.com/en/4.1/topics/i18n/translation/#interpolate
          const message = window.interpolate(
            window.ngettext(
              "%(count)s invoice has been sent.",
              "%(count)s invoices have been sent.",
              successCount,
            ),
            {
              count: successCount,
            },
            true,
          );
          Alpine.store("toasts").show(message);
        }
      },
      async uploadQueue() {
        if (this.uploading || !this.remainingFilesCount) return;
        this.uploading = true;
        await this.$nextTick();
        let successCount = 0;
        while (this.nextFile) {
          const file = this.nextFile;
          file.uploading = true;
          this.controller = new AbortController();

          const isUploadSuccessful = await uploadFile(
            file,
            this.controller.signal,
          );

          file.uploaded = true;
          file.uploading = false;
          await this.$nextTick(); // this fixes an issue with the file table not being reactive to array changes
          this.$store.files.unshift(file);

          if (!isUploadSuccessful) {
            this.errorType = "server";
            this.fileErrors.push(file);
          } else {
            successCount++;
          }
        }

        this.uploadedToastMessage(successCount);
        this.uploading = false;
        this.$nextTick(() => {
          this.files = this.files.filter((file) => !file.uploaded);
        });
      },
    };
  });
}
