export default function ibanForm(Alpine) {
  Alpine.data("ibanForm", function (ibanLast4, isSetup) {
    return {
      errorMessage: null,
      isIbanComplete: false,
      isSubmitting: false,
      stripe: null, // Stripe client
      isSetup: null,
      ibanLast4: null,
      isEmpty: false,

      init() {
        this.ibanLast4 = ibanLast4;
        this.isSetup = isSetup;
      },
      /**
       * Clear stripe SEPA in the backend
       */
      async updateSEPA() {
        this.isSubmitting = true;
        const response = await fetch(Alpine.store("globals").urls.uploadSEPA, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": document.querySelector("[name=csrfmiddlewaretoken]")
              .value,
          },
          body: JSON.stringify({
            iban: this.$refs.ibanField.value,
          }),
        });
        if (response.status !== 200) {
          this.errorMessage = `${window.gettext(
            "Silvr encountered an issue, please contact our support team.",
          )} [SEPA003]`;
          this.isSubmitting = false;
        } else {
          this.isSubmitting = false;
          const { payment_method_sepa_iban_last4, is_setup } =
            await response.json();
          this.ibanLast4 = payment_method_sepa_iban_last4;
          this.isSetup = is_setup;
          this.$refs.ibanField.value = "";
        }
        return response;
      },

      isValid() {
        const length = this.$refs.ibanField.value.length;
        // Quick & dirty, Stripe will complain anyway if it's invalid
        // We could implement this for an actual check https://stackoverflow.com/a/35599724
        return length > 8 && length <= 34;
      },

      /**
       * Clear and submit
       *
       */
      updateStripeSEPA() {
        if (!this.isValid()) {
          return;
        }

        this.updateSEPA();
      },
    };
  });
}
