export default function modal(Alpine) {
  Alpine.store("dialog", {
    openedModalId: null,
    opened: false,
    open(modalId) {
      if (this.openedModalId) {
        this.modalEl.close();
        this.opened = false;
        document.querySelector("body").classList.remove("modal-open");
      }
      this.openedModalId = modalId;
      this.modalEl.showModal();
      this.opened = true;
      document.querySelector("body").classList.add("modal-open");
    },
    close() {
      this.modalEl.close();
      this.openedModalId = null;
    },
    get modalEl() {
      const el = document.getElementById(this.openedModalId);
      const id = this.openedModalId;
      if (!el) {
        this.openedModalId = null;
        throw new Error(`Modal with id "${id}" not found`);
      }
      return el;
    },
  });
}
