export default function connectors(Alpine) {
  Alpine.data(
    "connectors",
    ({ listURL = "", disableURL = "", connections = [] }) => ({
      connections,
      async fetchIntegrations() {
        try {
          this.connections = (await (await fetch(listURL)).json()).data;
        } catch (e) {
          console.error(e);
        }
      },
      get countDisconnected() {
        return this.connections.filter((c) => c.status !== "Active").length;
      },
      /**
       * @param {obj}       params
       * @param {string}    params.uuid
       */
      async deleteConnection(item) {
        const disableIntegrationURL = new URL(disableURL, window.location);
        disableIntegrationURL.searchParams.set("uuid", item.uuid);
        const response = await fetch(disableIntegrationURL);

        if (response.ok) {
          this.connections = this.connections.filter(
            (c) => c.uuid !== item.uuid,
          );
          this.$modal.close();

          Alpine.store("toasts").show(
            window.gettext("The account has been successfully deleted."),
          );
        } else {
          this.$modal.close();
          Alpine.store("toasts").show(
            window.gettext(
              "Something went wrong, the account has not been deleted.",
            ),
            "error",
          );
        }
      },

      /**
       * @param {obj}       item
       * @param {string}    item.uuid
       * @param {string}    reconnectUrl
       */
      async reconnect(item, reconnectUrl) {
        this.$modal.details.isProcessingUpdate = true;
        const data = this.$modal.details.data;
        data.uuid = item.uuid;

        const response = await fetch(reconnectUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": document.querySelector("[name=csrfmiddlewaretoken]")
              .value,
          },
          body: JSON.stringify(data),
        });

        if (response.status !== 200) {
          let errorPayload = null;
          try {
            errorPayload = await response.json();
          } catch (e) {
            // unexpected format of the error response
            window.$silvr.utils.captureException(e);
          }

          // we check the format of the error to be sure this is a string and not an object
          // if the format is not correct we use an empty message so we will fallback on a default HTML message
          const message =
            typeof errorPayload?.error === "string" ? errorPayload.error : "";
          const code = errorPayload?.code ?? 0;

          this.$modal.details.error = { message, code };
          this.$modal.details.isInvalid = true;
          this.$modal.details.isProcessingUpdate = false;
        } else {
          const responseBody = await response.json();

          if (responseBody.redirect_url) {
            window.open(responseBody.redirect_url, "_self");
          } else {
            // refresh
            await this.fetchIntegrations();

            this.$modal.close();

            Alpine.store("toasts").show(
              window.gettext("The account has been successfully updated."),
            );
          }
        }
      },

      showInformationModal(item) {
        this.$modal.open("account_information-modal", {
          details: Object.assign(
            {
              item,
              data: {},
              isInvalid: false,
              isProcessingUpdate: false,
            },
            ["fino", "yapily"].includes(item.type)
              ? {
                  icon: item.icon_src,
                  name: item.name,
                  institution_id: item.id,
                  redirectUrl: `${
                    item.type === "fino"
                      ? "/integrations/fino/reconnect/"
                      : "/integrations/yapily/reauthorise/"
                  }${item.uuid}`,
                }
              : {},
          ),
        });
      },

      showDeleteModal(item) {
        this.$modal.open("delete_account", { details: item });
      },
    }),
  );
}
