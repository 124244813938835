/**
 * This polyfills the Dialog element for Safari 14+15
 * This is not a complete polyfill, just what we currently need.
 */

export const needsDialogPolyfill = () => {
  return !("HTMLDialogElement" in window);
};

export const shouldUpgrade = (el) => {
  return needsDialogPolyfill() && el.tagName === "DIALOG";
};

export const polyfillIsReady = () => {
  return Boolean(customElements.get("dialog-polyfill"));
};

export const whenReady = () => {
  return customElements.whenDefined("dialog-polyfill");
};

export default () => {
  class CustomDialog extends HTMLElement {
    lastActiveElement;

    showModal() {
      this.lastActiveElement = document.activeElement;
      this.show();
      this.classList.add("as-modal");
      document.addEventListener(
        "keydown",
        (e) => {
          if (e.key === "Escape") {
            this.dispatchEvent(
              new Event("cancel", { bubbles: true, cancelable: true }),
            );
          }
        },
        { once: true },
      );
    }

    close(returnValue) {
      this.returnValue = returnValue;
      this.removeAttribute("open");
      this.classList.remove("as-modal");
      this.lastActiveElement?.focus();
    }

    show() {
      this.setAttribute("role", "dialog");
      this.setAttribute("open", "");
    }

    get open() {
      return this.hasAttribute("open");
    }

    set open(val) {
      if (val) {
        this.show();
      } else {
        this.close();
      }
    }

    returnValue = "";
  }

  customElements.define("dialog-polyfill", CustomDialog);
};
