import Alpine from "alpinejs";
import htmx from "htmx.org";

const SESSION_COOKIE_NAME = window.SESSION_SETTINGS.SESSION_COOKIE_NAME;
const SESSION_WARNING_TIME =
  Number(window.SESSION_SETTINGS.SESSION_WARNING_TIME) || 60 * 5;
const TWENTY_SECONDS = 20_000;

const sessionExpiration = () =>
  window.$silvr.utils.getCookieValue(SESSION_COOKIE_NAME, null);

export const checkSessionExpiration = () => {
  if (!sessionExpiration()) return;
  const remaining = new Date(sessionExpiration()) - new Date();
  if (remaining <= 0) {
    dispatchExpiredToast();
    localStorage.removeItem("htmx-history-cache");
    // Disable HTMX history behavior to prevent backing into a closed session
    Object.assign(htmx.config, {
      historyEnabled: false,
      refreshOnHistoryMiss: true,
    });
  } else if (remaining <= SESSION_WARNING_TIME * 1000) {
    dispatchWarningToast();
    if (remaining > TWENTY_SECONDS)
      setTimeout(checkSessionExpiration, remaining / 4);
    else setTimeout(checkSessionExpiration, remaining);
  } else {
    if (activeToast?.show) Alpine.store("toasts").dismiss(activeToast);
    setTimeout(checkSessionExpiration, remaining - SESSION_WARNING_TIME * 1000);
  }
};

let activeToast = null;
const dispatchWarningToast = async () => {
  if (activeToast?.show) return;
  activeToast = await Alpine.store("toasts")?.show(
    window.gettext(
      "Your session is about to expire due to inactivity. To extend your session, please refresh the page.",
    ),
    "warning",
    0,
  );
};

const dispatchExpiredToast = async () => {
  if (activeToast?.show) Alpine.store("toasts").dismiss(activeToast);

  Alpine.store("dialog").open("session-expired");
};
