import anchor from "@alpinejs/anchor";
import Clipboard from "@ryangjchandler/alpine-clipboard";
import collapse from "@alpinejs/collapse";
import focus from "@alpinejs/focus";
import Headless from "./headless";
import intersect from "@alpinejs/intersect";
import mask from "@alpinejs/mask";
import Tooltip from "@ryangjchandler/alpine-tooltip";

export default [
  anchor,
  Clipboard,
  collapse,
  focus,
  Headless,
  intersect,
  mask,
  Tooltip,
];
