/**
 *
 */

export default function dateRangePicker(Alpine) {
  const MONTH_NAMES = [
    window.gettext("January"),
    window.gettext("February"),
    window.gettext("March"),
    window.gettext("April"),
    window.gettext("May"),
    window.gettext("June"),
    window.gettext("July"),
    window.gettext("August"),
    window.gettext("September"),
    window.gettext("October"),
    window.gettext("November"),
    window.gettext("December"),
  ];
  const DAY_NAMES = [
    window.gettext("Mon"),
    window.gettext("Tues"),
    window.gettext("Wed"),
    window.gettext("Thurs"),
    window.gettext("Fri"),
    window.gettext("Sat"),
    window.gettext("Sun"),
  ];

  // AlpineJs x-data object
  Alpine.data("dateRangePicker", (currentLanguageCode, placeholder) => ({
    MONTH_NAMES,
    DAY_NAMES,

    fromDate: null,
    toDate: null,

    fromDateCalendar: datePickerData(placeholder),
    toDateCalendar: datePickerData(placeholder),

    isToday,

    init() {
      this.initDatePickers();
    },
    initDatePickers() {
      this.fromDate = getDateFromQueryStringParam("from_date");
      this.toDate = getDateFromQueryStringParam("to_date");

      this.fromDateCalendar.name = "from_date";
      this.fromDateCalendar.datepickerValue = placeholder;
      this.fromDateCalendar.localeDateString = currentLanguageCode;
      this.fromDateCalendar.setup(this.$dispatch, this.fromDate);
      this.toDateCalendar.name = "to_date";
      this.toDateCalendar.datepickerValue = placeholder;
      this.toDateCalendar.localeDateString = currentLanguageCode;
      this.toDateCalendar.setup(this.$dispatch, this.toDate);
    },
    isFromDateBeforeToDate() {
      if (this.toDate === null || this.fromDate === null) {
        return true;
      }
      return this.fromDate <= this.toDate;
    },

    updateUrlWithSelectedDates(details) {
      const url = new URL(window.location.href);
      let dateQueryParam = "";
      try {
        dateQueryParam = formatDate(details.date);
        url.searchParams.set(details.name, dateQueryParam);
      } catch {
        url.searchParams.delete(details.name);
      }

      history.pushState({}, "", url);

      // hard reload
      location.reload(true);
    },
  }));
}

function datePickerData() {
  return {
    name: null,
    showDatepicker: false,
    selectedDate: null,
    datepickerValue: "",

    month: "",
    year: "",
    days: [],
    blankdays: [],

    previousMonth() {
      if (this.month === 0) {
        this.month = 11;
        this.year--;
      } else {
        this.month--;
      }
      this.getCalendarDays();
    },

    nextMonth() {
      if (this.month === 11) {
        this.month = 0;
        this.year++;
      } else {
        this.month++;
      }

      this.getCalendarDays();
    },

    toggleOpen() {
      this.showDatepicker = !this.showDatepicker;
    },

    close() {
      this.showDatepicker = false;
    },

    /**
     * @param {Alpine $dispatch} $dispatch
     * @param {Date} date - optional init date
     */
    setup($dispatch, date) {
      this.$dispatch = $dispatch;
      this.initDate(date);
      this.getCalendarDays();
    },

    initDate(date) {
      const today = date || new Date();

      this.month = today.getMonth();
      this.year = today.getFullYear();

      if (date) {
        this.selectedDate = date;
        this.setDateValue(this.selectedDate, false);
      }
    },

    isSelected(date) {
      const refDate = this.selectedDate || new Date();
      return refDate.toString() === date.toString();
    },

    /**
     * check if we should display the button to go to the next month
     */
    shouldDisplayNextMonthButton() {
      const currentMonth = this.month;
      const currentYear = this.year;

      const today = new Date();
      const todayMonth = today.getMonth();
      const todayYear = today.getFullYear();

      return !(currentMonth === todayMonth && currentYear === todayYear);
    },

    /**
     * Get currently selected date
     *
     * @param {Date} date
     * @param {str} queryParamName - from | to
     */
    setDateValue(date, dispatch = true) {
      this.selectedDate = date;

      if (this.selectedDate) {
        this.datepickerValue = this.selectedDate.toLocaleDateString(
          this.localeDateString,
        );
      }

      this.showDatepicker = false;
      if (dispatch) {
        this.$dispatch("date-selected", {
          date,
          dateStr: this.datepickerValue,
          name: this.name,
        });
      }
    },

    /**
     * Reset back to initial state
     */
    reset(placeholder) {
      this.selectedDate = null;
      this.datepickerValue = placeholder;
      this.setup(this.$dispatch);
      this.setDateValue(null);
    },

    /**
     * Fill up days and blankdays arrays for current month
     */
    getCalendarDays() {
      const today = new Date();
      const fromDate = getDateFromQueryStringParam("from");
      const toDate = getDateFromQueryStringParam("to");

      const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();

      // find where to start calendar day of week
      const dayOfWeek = new Date(this.year, this.month).getDay();
      const mondayDayOfWeek = (dayOfWeek + 6) % 7;
      const blankdaysArray = [];
      for (let i = 1; i <= mondayDayOfWeek; i++) {
        blankdaysArray.push(i);
      }

      const daysArray = [];
      for (let i = 1; i <= daysInMonth; i++) {
        const dayDate = new Date(this.year, this.month, i);
        dayDate._day = i;
        const isAfterToday = dayDate > today;
        const isAfterToDate = toDate ? dayDate > toDate : false;
        const isBeforeFromDate = fromDate ? dayDate < fromDate : false;
        dayDate._isToday = isToday(dayDate);
        dayDate._isDisabled = isAfterToday;

        // cant select dates < from
        if (this.name === "to" && toDate) {
          dayDate._isDisabled = isAfterToday || isBeforeFromDate;
        }
        // cant select dates > to
        if (this.name === "from") {
          dayDate._isDisabled = isAfterToday || isAfterToDate;
        }
        dayDate._isSelected = this.isSelected(dayDate);

        dayDate._isSelectable = !dayDate._isSelected && !dayDate._isDisabled;
        daysArray.push(dayDate);
      }

      this.blankdays = blankdaysArray;
      this.days = daysArray;
    },
  };
}

/**
 * Check id date is today
 *
 * @param {Date} date
 */
const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const formatDate = (date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

/**
 * Get date from URL query string
 *
 * @param {str} paramName
 */
function getDateFromQueryStringParam(paramName) {
  const dateStr = new URL(window.location.href).searchParams.get(paramName);
  if (!dateStr) {
    return null;
  }
  let [year, month, day] = dateStr.split("-");
  return new Date(year, --month, day);
}
