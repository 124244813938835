export default function (Alpine) {
  Alpine.data("companySelect", () => {
    const otherValue = "other-offline";
    return {
      choice: null,
      optionTemplate: null,
      init() {
        import(/* webpackChunkName: "custom-select" */ "choices.js").then(
          (module) => {
            const CustomSelect = module.default;
            this.choice = new CustomSelect(this.$refs.select, {
              searchEnabled: false,
              itemSelectText: "",
              allowHTML: true,
              shouldSort: false,
              callbackOnCreateTemplates: this.createTemplate,
            });
          },
        );
      },
      createTemplate(template) {
        const selectSprite = `${Alpine.store("globals").urls.selectSprite}`;
        const getIcon = (value) =>
          value
            ? `<svg class="w-5 h-5 text-neutral-500"><use xlink:href="${selectSprite}#${value.toLowerCase()}"></use></svg>`
            : "";
        return {
          item: ({ classNames }, data) => {
            return template(`
          <div class="${classNames.item} ${
            data.highlighted
              ? classNames.highlightedState
              : classNames.itemSelectable
          } ${
            data.placeholder ? classNames.placeholder : ""
          } flex gap-2 items-center" data-item data-id="${data.id}" data-value="${
            data.value
          }" ${data.active ? 'aria-selected="true"' : ""} ${
            data.disabled ? 'aria-disabled="true"' : ""
          }>
            ${getIcon(data.value)} ${data.label}
          </div>
        `);
          },
          choice: ({ classNames }, data) => {
            return template(`
          <div class="${classNames.item} ${classNames.itemChoice} ${
            data.disabled ? classNames.itemDisabled : classNames.itemSelectable
          } ${
            data.selected ? "choices__item--selected" : ""
          } flex gap-2 items-center" data-select-text="${
            this.config.itemSelectText
          }" data-choice ${
            data.disabled
              ? 'data-choice-disabled aria-disabled="true"'
              : "data-choice-selectable"
          } data-id="${data.id}" data-value="${data.value}" ${
            data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
          }>
          ${getIcon(data.value)} ${data.label}
          </div>
        `);
          },
        };
      },
      companyChange({ target }, focus = true) {
        const isOther = target.value === otherValue;
        this.$refs.otherField.required = isOther;

        if (!isOther) {
          this.$refs.otherField.focus();
          this.$nextTick(() => target.focus());
        }

        if (focus && isOther) {
          this.$nextTick(() => this.$refs.otherField.focus());
        }

        target.dispatchEvent(
          new CustomEvent("companychange", {
            bubbles: true,
            detail: { isOther, value: target.value },
          }),
        );
      },
    };
  });
}
