import { fuzzyInsertionSearch } from "../../utils";
import { hasProperty } from "./utils";

export const combobox = (Alpine) => {
  Alpine.directive("combobox", (el, directive, utils) => {
    if (!directive.value) return handleRoot(el, directive, utils);
    const comboBox = getCombobox(el, Alpine);
    if (!comboBox) {
      return console.error(
        "Alpine Combobox: Element with directive:",
        directive.original,
        "is not inside a combobox root",
      );
    }
    (comboboxValues[directive.value] || failback)(
      comboBox,
      el,
      directive,
      utils,
    );
  }).before("bind");
  Alpine.magic("combobox", (el) => getCombobox(el, Alpine));
};

const comboBoxes = new WeakMap();

const getCombobox = (el, Alpine) => {
  const root = Alpine.findClosest(el, (el) => comboBoxes.has(el));
  return comboBoxes.get(root);
};

const handleRoot = (el, _directive, { Alpine }) => {
  const comboboxState = Alpine.reactive({
    open: false,
    query: "",
  });
  comboBoxes.set(el, comboboxState);

  const closePanel = () => {
    comboboxState.open = false;
  };
  Alpine.bind(el, {
    "@keydown.escape": closePanel,
    "@click.outside": closePanel,
    "@focusin.outside": closePanel,
  });
};

const handleSearch = (
  combobox,
  el,
  { modifiers, expression },
  { Alpine, evaluateLater },
) => {
  const openCombobox = () => {
    combobox.open = true;
  };
  combobox.query = el.value;
  const evaluate = evaluateLater(expression);
  Alpine.bind(el, {
    role: "search",
    ":value"() {
      return combobox.query;
    },
    [["@input", ...modifiers].join(".")]() {
      openCombobox();

      combobox.query = el.value;
      evaluate();
    },
    "@click": openCombobox,
    "@focus": openCombobox,
  });
};

const handlePanel = (combobox, el, _directive, { Alpine }) => {
  Alpine.bind(el, {
    "x-collapse": true,
    "@keydown.arrow-up.stop.prevent": "$focus.wrap().previous()",
    "@keydown.arrow-down.stop.prevent": "$focus.wrap().next()",
    "x-show": () => combobox.open,
  });
};

const handleOption = (combobox, el, { expression }, { Alpine, evaluate }) => {
  const label = evaluate(expression);
  Alpine.bind(el, {
    role: "option",
    "x-show": () => fuzzyInsertionSearch(combobox.query, label),
  });
};

const comboboxValues = {
  search: handleSearch,
  panel: handlePanel,
  option: handleOption,
};

const failback = (_, el, directive) => {
  console.error(
    "Alpine Combobox: Invalid combobox directive",
    el,
    directive.original,
  );
};
