export default function (Alpine) {
  Alpine.data("bankSca", () => ({
    bank: null,
    state: "connecting",
    consentId: "",
    scaMethods: [],
    scaMethod: "",
    scaCode: "",
    error: "",
    disabledConsentPolling: false,
    init() {
      this.$modal.addEventListener("open-modal", (e) => {
        if (e.detail?.id !== "sca-modal") return;
        this.bank = this.$modal.details;
        this.handleEmbeddedAuth();
      });
      this.$watch("state", (value) => {
        if (
          ![
            "connecting",
            "awaiting_sca_method",
            "awaiting_sca_code",
            "awaiting_decoupled_authorization",
            "awaiting_authorization",
          ].includes(value)
        ) {
          this.$modal.open("bank-connection-error", {
            details: { bank_id: this.bank.bank_id },
          });
        }
      });
    },
    async handleEmbeddedAuth() {
      this.state = "connecting";
      if (this.bank.connectionUrl) {
        this.error = "";
        this.scaCode = "";
        this.disabledConsentPolling = false;

        try {
          const response = await fetch(this.bank.connectionUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": document.querySelector(
                "[name=csrfmiddlewaretoken]",
              ).value,
            },
            body: JSON.stringify({
              PSUFieldValue: this.bank.PSUFieldValue,
              PSUFieldName: this.bank.PSUFieldName,
              username: this.bank.username,
              password: this.bank.password,
              selectedBank: this.bank.selectedBank,
              postCreateRedirectPath: this.bank.postCreateRedirectPath,
            }),
          });

          if (!response.ok) throw new Error(await response.json());
          await sleep(1000);
          // 1 sec timeout to let the user see the loading state modal.
          // This could be improved with a loader on the parent modal and not displaying this loading state modal.
          const data = await response.json();
          const newState = data.status
            ? data.status.toLowerCase()
            : "awaiting_sca_method";
          if (newState === "awaiting_authorization") {
            window.location.replace(data.authorizationUrl);
          } else {
            this.state = newState;
            this.consentId = data.id;
            this.scaMethods = data.scaMethods;
          }
        } catch (e) {
          this.bank.connectionUrl = null;
          const error = e.error
            ? e.error
            : "An error has occurred, please try again later";
          this.$modal.open("yapily-request-summary-modal", {
            details: {
              ...this.bank,
              error,
            },
          });
        }
      }
    },
    selectScaMethod(scaMethodUrl, scaMethodId) {
      this.state = "connecting";
      this.error = "";
      this.scaMethod = scaMethodId;
      fetch(scaMethodUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": document.querySelector("[name=csrfmiddlewaretoken]")
            .value,
        },
        body: JSON.stringify({
          consentId: this.consentId,
          scaMethodId,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            // 3 sec timeout to let the user see the loading state modal. Here we need a longer timeout because of the UX.
            // This could be improved with a loader on the parent modal and not displaying this loading state modal.
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(response.json());
              }, 3000);
            });
          }
          throw Error(response.statusText);
        })
        .then((data) => {
          this.state = data.status
            ? data.status.toLowerCase()
            : "awaiting_sca_code";
          if (this.state === "awaiting_decoupled_authorization") {
            this.pollConsentStatus(this.consentId);
          }
          return this.state;
        })
        // we don't want to show custom error message to the user at this step
        .catch(() =>
          this.$modal.open("bank-connection-error", {
            details: { bank_id: this.bank.bank_id },
          }),
        );
    },
    async selectScaCode(scaCodeUrl, scaCode) {
      this.state = "connecting";
      this.error = "";
      try {
        const response = await fetch(scaCodeUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": document.querySelector("[name=csrfmiddlewaretoken]")
              .value,
          },
          body: JSON.stringify({
            consentId: this.consentId,
            scaCode,
            PSUFieldValue: this.bank.PSUFieldValue,
            PSUFieldName: this.bank.PSUFieldName,
            selectedBank: this.bank.selectedBank,
          }),
        });
        if (!response.ok) throw new Error(await response.json());
        await sleep(1000);
        const data = response.json();

        window.location.replace(data.redirect_url);
      } catch (e) {
        this.error = e.error
          ? e.error
          : "An error has occurred, please try again later";
        this.state = "awaiting_sca_code";
      }
    },
    pollConsentStatus(consentId, numAttempts = 0, maxAttempts = 150) {
      this.error = "";
      fetch(`/integrations/yapily/consent-status/${consentId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          return response.json().then((error) => Promise.reject(error));
        })
        .then((data) => {
          if (this.disabledConsentPolling) {
            return;
          }
          numAttempts += 1;
          this.state = data.status
            ? data.status.toLowerCase()
            : "awaiting_decoupled_authorization";
          if (this.state === "authorized") {
            window.location.replace(this.bank.postCreateRedirectPath);
          } else if (numAttempts <= maxAttempts) {
            setTimeout(
              () => this.pollConsentStatus(consentId, numAttempts),
              2000,
            );
          } else {
            throw Error(
              window.gettext("Time for connection expired, please try again"),
            );
          }
          return this.state;
        })
        .catch((e) => {
          const error = e.error
            ? e.error
            : window.gettext("Time for connection expired, please try again");
          this.$modal.open("yapily-request-summary-modal", {
            details: {
              ...this.bank,
              error,
            },
          });
        });
    },
    disableConsentPolling() {
      this.disabledConsentPolling = true;
    },
  }));
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
