import { sizeAsString } from "../../utils";

export default function fileUploader(Alpine) {
  Alpine.data("fileUploader", (params) => {
    const {
      allowedExtensions,
      allowedFilesize = 100,
      endpoint = "",
      extraFormData = {},
    } = params;
    return {
      allowedExtensions,
      allowedFilesize: allowedFilesize * 1024 ** 2,
      endpoint,
      initialFileCount: 0,
      files: [],
      fileErrors: [],
      get uploading() {
        return Boolean(this.currentFile);
      },
      dragging: false,
      controller: undefined,
      get currentFile() {
        return this.files.find((file) => file.state === "uploading");
      },
      get nextFile() {
        return this.files.find((file) => file.state === "pending");
      },
      get totalFilesCount() {
        return this.files.length;
      },
      get uploadedFilesCount() {
        return this.totalFilesCount - this.remainingFilesCount;
      },
      get remainingFilesCount() {
        return this.files.filter((file) => file.state === "pending").length;
      },
      get errorMessage() {
        return window.interpolate(
          window.ngettext(
            "%s file has been ignored because of incorrect format (accepted: %s) or larger than %s.",
            "%s files have been ignored because of incorrect format (accepted: %s) or larger than %s.",
            this.fileErrors.length,
          ),
          [
            this.fileErrors.length,
            allowedExtensions,
            sizeAsString(this.allowedFilesize),
          ],
        );
      },
      abort() {
        const file = this.currentFile;
        file.state = "aborted";
        this.controller?.abort();
        this.$dispatch("upload-aborted", {
          message: `${file.filename}.${file.ext} ${window.gettext(
            "upload aborted",
          )}`,
        });
      },
      abortAll() {
        this.controller?.abort();
        this.files = [];
      },
      handleFileInput(e) {
        this.addFiles(this.processFiles(e.target.files));
        this.uploadQueue();
        e.target.value = "";
      },
      handleDrop(e) {
        this.addFiles(this.processFiles(e.dataTransfer.files));
        this.dragging = false;
        this.uploadQueue();
        e.target.value = "";
      },
      addFiles({ ready, error }) {
        this.resetFiles();
        this.files.push(...ready);
        this.fileErrors.push(...error);
      },
      async uploadQueue() {
        if (this.fileErrors.length) {
          this.$dispatch("upload-errors");
        }
        this.$dispatch("upload-started");
        let nbSuccessFullUpload = 0;
        while (this.nextFile) {
          const file = this.nextFile;
          file.state = "uploading";

          const { status: uploadStatus, value } = await this.uploadFile(file);

          switch (uploadStatus) {
            case "success":
              this.$dispatch("upload-success", {
                id: value.created_document,
                category: file.category,
                status: "uploaded", // Required to check if all required docs are uploaded
              });
              nbSuccessFullUpload++;
              file.state = "uploaded";
              break;
            case "error":
              file.state = "aborted";
              this.fileErrors.push(file);
              break;
            case "aborted":
              file.state = "aborted";
              break;
          }
        }

        this.files = this.files.filter((file) => !file.state === "uploaded");

        if (!this.nextFile) {
          let message = "";
          if (
            nbSuccessFullUpload > 0 &&
            extraFormData.category === "bank_statement"
          ) {
            message = window.interpolate(
              window.ngettext(
                "%s/%s bank statement uploaded successfully",
                "%s/%s bank statements uploaded successfully",
                nbSuccessFullUpload,
              ),
              [nbSuccessFullUpload, this.initialFileCount],
            );
          } else if (nbSuccessFullUpload > 0) {
            message = window.interpolate(
              window.ngettext(
                "%s/%s file uploaded successfully",
                "%s/%s files uploaded successfully",
                nbSuccessFullUpload,
              ),
              [nbSuccessFullUpload, this.initialFileCount],
            );
          }
          this.$dispatch("uploads-ended", {
            message,
            errorCount: this.fileErrors.length,
          });
        }
      },
      resetFiles() {
        this.files = [];
        this.fileErrors = [];
      },
      processFiles(fileList) {
        this.fileErrors = [];
        this.initialFileCount = fileList.length;
        const detailsArray = Array.from(fileList).map((file, index) => ({
          id: index,
          state: "pending",
          category: extraFormData.category,
          ...this.getFileDetails(file),
          file,
        }));
        return detailsArray.reduce(
          (status, file) => {
            const action =
              !this.allowedExtensions.some((allowedExtension) =>
                allowedExtension.includes(file.ext),
              ) || file.file.size > this.allowedFilesize
                ? "error"
                : "ready";
            status[action].push(file);
            return status;
          },
          { ready: [], error: [] },
        );
      },
      async uploadFile(file, signal) {
        this.controller = new AbortController();
        const formData = new FormData();
        formData.append("document_file", file.file);

        for (const [key, value] of Object.entries(extraFormData)) {
          formData.set(key, value);
        }

        try {
          const response = await fetch(this.endpoint, {
            method: "post",
            headers: {
              "X-CSRFToken": document.querySelector(
                "[name=csrfmiddlewaretoken]",
              ).value,
            },
            body: formData,
            signal: this.controller.signal,
          });

          const value = await response.json();

          return response.ok
            ? { status: "success", value }
            : { status: "error" };
        } catch (e) {
          if (e.message.includes("aborted")) {
            return { status: "aborted" };
          }
          return { status: "error" };
        }
      },
      getFileDetails(file) {
        const namePieces = file.name.split(".");
        const [filename, ext] = [
          namePieces[0],
          namePieces[namePieces.length - 1].toLowerCase(),
        ];
        return {
          filename,
          ext,
          filesizeString: sizeAsString(file.size),
        };
      },
    };
  });
}
