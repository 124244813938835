export const appEventHandlers = () => {
  window.addEventListener("resize", setScrollWidth);
  window.addEventListener("htmx:before-history-save", cleanupHTMXConflicts);
  setScrollWidth();
};

// Sets a CSS variable to the size of the scrollbar for CSS to use in cases for full-screen overlays
const setScrollWidth = () => {
  document.documentElement.style.setProperty(
    "--scroll-width",
    `${window.innerWidth - document.documentElement.clientWidth + 2}px`,
  );
};

// Cleans up elements that would conflict with hx-boost hot swapping
const cleanupHTMXConflicts = () => {
  document.querySelectorAll("[data-x-cleanup]").forEach((el) => el.remove());
};
