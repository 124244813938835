/**
 * Local storage SET with expire
 *
 * @param {String} key
 * @param {String} value
 * @param {int} ttl
 */
function setWithExpiry(key, value, ttl) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function whenLibAvailable(name, callback) {
  return new Promise((resolve) => {
    if (window[name]) callback(window[name]);
    else {
      Object.defineProperty(window, name, {
        set(value) {
          Object.defineProperty(window, name, {
            value,
            writable: true,
          });
          if (callback) callback(value);
          resolve(value);
        },
        get() {
          return undefined;
        },
        configurable: true,
      });
    }
  });
}

function downloadFile(path) {
  const link = document.createElement("a");
  link.href = path;
  link.download = path.substr(path.lastIndexOf("/") + 1);
  link.click();
}

function fuzzyInsertionSearch(queryString, testString) {
  const query = queryString.toLowerCase();
  const test = testString.toLowerCase();
  let queryMarker = 0;
  let testMarker = 0;
  while (queryMarker < query.length && testMarker < test.length) {
    if (query[queryMarker] === test[testMarker]) queryMarker++;
    testMarker++;
  }
  return queryMarker === query.length;
}

function sizeAsString(bytes) {
  const sizes = ["Octet", "Ko", "Mo", "Go", "To"];
  if (bytes === 0) return "0 Byte";
  const i = Number.parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
}

/**
 * @params {string} [selector] - A valid CSS selector
 * @params {HTMLElement} [context] - The HTML element from which the search is triggered
 */
function loadJsonScript({
  selector = 'script[type="application/json"]',
  context = document,
}) {
  const scriptElement = context.querySelector(selector);
  try {
    return JSON.parse(scriptElement?.textContent);
  } catch (err) {
    throw new Error(
      `$silvr.utils.loadJsonScript function failed on element ${selector}.`,
      { cause: err },
    );
  }
}

function loadScript(scriptUrl) {
  const script = document.createElement("script");
  script.src = scriptUrl;
  document.body.appendChild(script);

  return new Promise((resolve, reject) => {
    script.onload = function () {
      resolve(true);
    };
    script.onerror = function () {
      reject(new Error(`Something went wrong loading ${scriptUrl}`));
    };
  });
}

function getCookieValue(name, fallbackValue = "") {
  return (
    document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() ||
    fallbackValue
  );
}

function normalizeString(str) {
  // biome-ignore lint/suspicious/noMisleadingCharacterClass: legacy
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function formatCurrency(amount, locale = "fr-FR", currencyLocale = {}) {
  return new Intl.NumberFormat(locale, currencyLocale).format(amount);
}

function getMonthlyRepayment(amount, commissionPercentage, installmentsCount) {
  return (
    (amount * (1 + commissionPercentage / 100)) /
    installmentsCount
  ).toFixed(2);
}

function getTotalCommission(amount, commissionPercentage) {
  return ((amount * commissionPercentage) / 100).toFixed(2);
}

/**
 * Offer commission percentage calculation
 * should match silvr-app/silvr/contracting/business_logic/processor.py#CommissionProcessor::commission_percentage
 * @param {number} numberOfInstallments
 * @param {Date} offerPayoutDate
 * @param {number} expectedInterestRate
 * @returns {number}
 */

function getCommissionPercentage(
  numberOfInstallments,
  offerPayoutDate,
  expectedInterestRate,
) {
  const totalReturn = Array.from(
    { length: numberOfInstallments },
    (_, k) => k + 1,
  )
    .map(
      (i) =>
        1 /
        (1 + expectedInterestRate / 100) **
          (getDaysBetween(offerPayoutDate, monthsLater(offerPayoutDate, i)) /
            365),
    )
    .reduce((acc, i) => acc + i, 0);
  return (numberOfInstallments / totalReturn - 1) * 100;
}

function monthsLater(start, months) {
  const date = new Date(start);
  const targetMonth = date.getMonth() + months;
  date.setMonth(date.getMonth() + months);
  if (date.getMonth() !== targetMonth % 12) date.setDate(0);
  return date;
}

function getDaysBetween(start, end) {
  const diff = end.getTime() - start.getTime();
  return Math.round(diff / (1000 * 60 * 60 * 24));
}

export {
  setWithExpiry,
  whenLibAvailable,
  downloadFile,
  fuzzyInsertionSearch,
  sizeAsString,
  loadJsonScript,
  loadScript,
  getCookieValue,
  normalizeString,
  formatCurrency,
  getMonthlyRepayment,
  getTotalCommission,
  getCommissionPercentage,
};
