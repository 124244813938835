export default function (Alpine) {
  Alpine.data("readonlyConnector", (pk, connected) => ({
    storageKey: `revenue_connector_${pk}_invitation_based_email_copied`,
    copied: false,
    connected,
    init() {
      if (!this.connected) {
        this.connected = this.isCopied;
      }
    },
    async copyToClipboard(email) {
      try {
        await navigator.clipboard.writeText(email);
        this.$store.toasts.show(
          window.interpolate(
            window.gettext("Email %s has been copied successfully."),
            [email],
          ),
        );
        localStorage.setItem(
          this.storageKey,
          Number.parseInt(localStorage.getItem(this.storageKey) ?? 0, 10) + 1,
        );
        this.connected = true;
      } catch (err) {
        this.$store.toasts.show(
          window.gettext("Email could not be copied."),
          "error",
        );
      }
    },
    get isCopied() {
      return !!localStorage.getItem(this.storageKey);
    },
  }));
}
